/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(238, 28%, 21%);
  --clr-primary-2: hsl(238, 11%, 2%);
  --clr-primary-3: hsl(238, 28%, 37%);
  --clr-primary-4: hsl(238, 28%, 45%);
  /* primary/main color */
  --clr-primary-5: hsl(238, 31%, 52%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(238, 31%, 60%);
  --clr-primary-7: hsl(238, 31%, 67%);
  --clr-primary-8: hsl(238, 31%, 74%);
  --clr-primary-9: hsl(238, 31%, 81%);
  --clr-primary-10: hsl(238, 31%, 88%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}

.loading .dot {
  position: absolute;
  border-radius: 50%;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  background: 'red';
  animation: spin 2.5s 0s infinite both;
}

.loading .dot2 {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: 'red';
  animation: spin2 2.5s 0s infinite both;
}

@keyframes spin {
  0%, 100% {
   box-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red; 
  }
  50% {
    transform: rotate(180deg);
  }
  25%, 75% {
    box-shadow: 28px 0 0 red, -28px 0 0 red, 0 28px 0 red, 0 -28px 0 red, 20px -20px 0 red, 20px 20px 0 red,  -20px -20px 0 red, -20px 20px 0 red;

  }
  100% {
    transform: rotate(360deg);
	 box-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red; 
  }
}

@keyframes spin2 {
  0%, 100% {
   box-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red; 
  }
  50% {
    transform: rotate(-180deg);
  }
  25%, 75% {
    box-shadow: 52px 0 0 red, -52px 0 0 red, 0 52px 0 red, 0 -52px 0 red, 38px -38px 0 red, 38px 38px 0 red,  -38px -38px 0 red, -38px 38px 0 red;
    background: transparent;
  }
  100% {
    transform: rotate(-360deg);
	 box-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red; 
  }
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: var(--clr-primary-4) !important;
	border-color: var(--clr-primary-4) !important;
}

.pagination {
	margin-bottom: 2em;
	text-align: center !important;
	display: flex;
	justify-content: center;
}

.pagination button {
	margin-right: 10px;
	margin-bottom: 3px;
	display: inline-block;
}

.pagination li button {
	border-radius: 50% !important;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-left: 2px;
	margin-right: 2px;
	display: inline-block;
	text-align: center;
  background-color: #fff;
}

.pagination li button:focus,
.pagination li button:active {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.pagination li button:hover {
	background: var(--clr-primary-4);
	color: #fff;
	border: 1px solid transparent;
}

.pagination li.active button {
	border: 1px solid transparent !important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}